import React from 'react';
import classnames from 'classnames';

import './Title.scss';

const styles = {
  huge: 'title-huge',
  veryLarge: 'title-very-large',
  large: 'title-large',
  regular: 'title-regular',
};

export default function Title (props) {
  const Tag = props.tag || 'h1';

  return (
    <Tag {...props} className={ classnames(props.className, styles[props.type || 'regular']) }>
      { props.children }
    </Tag>
  );
}
