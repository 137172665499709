import React from 'react';
import Title from './Title';
import classnames from 'classnames';

import './Header.scss';

const styles = {
  huge: 'header-huge',
  veryLarge: 'header-very-large',
  large: 'header-large',
  regular: 'header-regular',
};

export default function Header (props) {
  return (
    <Title {...props} className={classnames(props.className, styles[props.type || 'regular'])}>
      { props.children }
    </Title>
  );
}
