import lang from '../Text/en.json';

export function trans(key, replacements = {}) {
  const pre = lang[key] !== undefined ? lang[key] : key;

  return pre.replace(
    /:[a-z]+/gi,
    match => replacements[match.slice(1)] || match
  );
}
