import React from 'react';
import classnames from 'classnames'
import './Link.scss';

export default function Link (props) {
  return (
    <a
      href={props.href || '#'}
      className={classnames(props.className, 'link')}
    >
      {props.children}
    </a>
  );
}
