import React from 'react';
import classnames from 'classnames';

import sizes from 'style/sizes.scss';

export default function Tick (props) {
  return (
      <svg
          viewBox="0 0 16 16"
          className={classnames(props.className)}
          width={props.size || sizes.iconRegular}
          height={props.size || sizes.iconRegular}
      >
        <path d="M8 16A8 8 0 118 0a8 8 0 010 16zM6.636 9.637L4.453 7.454a.772.772 0 00-1.091 1.092l2.728 2.728a.772.772 0 001.092 0l5.456-5.457a.772.772 0 00-1.091-1.091L6.636 9.637z" fill={props.fill || "#000"} fillRule="nonzero"/>
    </svg>
  );
}
