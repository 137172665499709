import useMediaQuery from 'react-use-media-query-hook';

/**
 * Subscribes a component to media query updates.
 *
 * @return Object
 */
export default function () {
  return {
    small: useMediaQuery('(min-width: 576px)'),
    medium: useMediaQuery('(min-width: 768px)'),
    large: useMediaQuery('(min-width: 992px)'),
    huge: useMediaQuery('(min-width: 1200px)'),
  }
}
