import { trans } from '../../Helpers/Lang';

export default [
  //{
    //title: trans('footer.company'),
    //links: [
      //{
        //text: trans('footer.aboutUs'),
      //},
      //{
        //text: trans('footer.twitter'),
      //},
      //{
        //text: trans('footer.facebook'),
      //},
    //],
  //},
  //{
    //title: trans('footer.myAccount'),
    //links: [
      //{
        //text: trans('footer.signUp'),
        //filter: props => !props.authenticated
      //},
      //{
        //text: trans('footer.login'),
        //filter: props => !props.authenticated
      //},
      //{
        //text: trans('footer.bookings'),
        //filter: props => props.authenticated
      //},
      //{
        //text: trans('footer.profile'),
        //filter: props => props.authenticated
      //},
      //{
        //text: trans('footer.info'),
        //filter: props => props.authenticated
      //},
    //],
  //},
  //{
    //title: trans('footer.termsPolicies'),
    //links: [
      //{
        //text: trans('footer.termsOfService'),
      //},
      //{
        //text: trans('footer.privacy'),
      //},
    //],
  //}
];
