import { trans } from 'Helpers/Lang';

import booking from 'assets/desktop/booking.png';
import link from 'assets/desktop/link.png';
import paid from 'assets/desktop/paid.png';

export default [
  {
    src: link,
    title: trans('index.features.sendLink'),
    text: trans('index.features.sendLinkText'),
    imageWidth: '180px',
    imageHeight: '180px',
  },
  {
    src: booking,
    title: trans('index.features.getBooked'),
    text: trans('index.features.getBookedText'),
    imageWidth: '180px',
    imageHeight: '180px',
  },
  {
    src: paid,
    title: trans('index.features.getPaid'),
    text: trans('index.features.getPaidText'),
    imageWidth: '180px',
    imageHeight: '180px',
  },
];
