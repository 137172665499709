import React from 'react';
import classnames from 'classnames';

import sizes from 'style/sizes.scss';

export default function Clock (props) {
  return (
      <svg
          viewBox="0 0 16 16"
          className={classnames(props.className)}
          width={props.size || sizes.iconRegular}
          height={props.size || sizes.iconRegular}
      >
      <path d="M7.887 9.843A.85.85 0 008.85 9V5a.85.85 0 10-1.7 0v3.15H5a.85.85 0 100 1.7h2.774a.858.858 0 00.113-.007zm6.152-4.384A7 7 0 111.965 5.45a2.079 2.079 0 01-1.017-.627 1.965 1.965 0 01.204-2.814L2.389.972a2.088 2.088 0 012.9.21c.303.339.467.755.495 1.176A6.993 6.993 0 018 2c.788 0 1.546.13 2.253.37.026-.425.19-.846.495-1.189a2.088 2.088 0 012.9-.21l1.238 1.04a1.965 1.965 0 01.203 2.813c-.289.324-.657.537-1.05.635z" fill={props.fill || "#000"} fillRule="nonzero"/>
    </svg>
  );
}
