import React, { useState } from 'react';
import Container from '../Components/Layout/Container';
import Grid from '../Components/Layout/Grid';
import Image from '../Components/Presentation/Image';
import Feature from '../Components/Presentation/Feature';
import DateBox from '../Components/Presentation/DateBox';
import Body from '../Components/Text/Body';
import HeaderSubtitle from '../Components/Text/HeaderSubtitle';
import Input from 'Components/Form/Input';
import Button from 'Components/Form/Button/Button';
import useFirebase from 'Hooks/useFirebase'
import { trans } from 'Helpers/Lang';
import features from 'Content/index/features';
import useMedia from 'Hooks/useMediaQuery';

import bookingCards from 'assets/desktop/booking-cards.svg';
import bookingCardsMobile from 'assets/mobile/booking-cards.svg';
import background from 'assets/desktop/background.svg';
import payment from 'assets/desktop/payment.svg';
import itinerary from 'assets/desktop/itinerary.svg';
import itineraryMobile from 'assets/mobile/itinerary.svg';
import partialLogo from 'assets/desktop/partial-logo.svg';
import tribe from 'assets/desktop/tribe.jpg';
import tribeMobile from 'assets/mobile/tribe.jpg';

import sizes from 'style/sizes.scss';
import collections from 'firebase/collections';

import './Index.scss';

export default function Index (props) {
  const medias = useMedia();
  const [email, setEmail] = useState('');
  const [email2, setEmail2] = useState('');
  const [subscribed, setSubscribed] = useState(false);
  const [subscribed2, setSubscribed2] = useState(false);

  const firebase = useFirebase();

  function onSubmit(email) {
    if (!email) return;

    firebase.firestore()
      .collection(collections.emails)
      .add({ email })
      .then(() => setSubscribed(true))
  }

  function onSubmit2(email) {
    if (!email) return;

    firebase.firestore()
      .collection(collections.emails)
      .add({ email })
      .then(() => setSubscribed2(true))
  }

  return (
    <main>
      <Image
        fill
        src={null}
        tag="section"
        className="hero"
      >
        <Container>
          <div className="hero-header">
            <HeaderSubtitle
              type={medias.medium ? 'huge' : 'veryLarge'}
              title={trans('index.hero.title')}
            >
              {trans('index.hero.text')}
            </HeaderSubtitle>
            <Input
              required
              success={subscribed}
              value={email}
              label={trans('index.hero.input')}
              placeholder={trans('index.hero.placeholder')}
              onChange={(name, val, e) => setEmail(val)}
              type="email"
              autoComplete="email"
            />
            <Button
              block
              onClick={() => onSubmit(email)}
            >
              {trans('index.hero.submit')}
            </Button>
          </div>
        </Container>
      </Image>
      <Container
        tag={Grid}
        proxy={{
          tag: 'section',
          columns: medias.medium ? features.length : 1,
          gap: medias.medium ? sizes.paddingMassive : sizes.paddingVeryLarge
        }}
        className="features"
      >
        {features.map((feature, key) => (
          <Feature
            key={key}
            {...feature}
            alt=""
          />
        ))}
      </Container>
      <Image
        fill
        className="explan-bg"
        src={background}
      >
        <Container tag="section">
          <HeaderSubtitle
            wrapperClassName="bookings-header"
            type={medias.medium ? 'huge' : 'veryLarge'}
            title={trans('index.bookings.title')}
          >
            {trans('index.bookings.text')}
          </HeaderSubtitle>
          <Image
            className="bookings-example"
            src={medias.medium ? bookingCards : bookingCardsMobile}
          />
        </Container>
        <Container
          tag="section"
          className="payments"
        >
          <Grid
            columns={medias.medium ? 2 : 1}
            gap={medias.medium ? sizes.paddingMassive : sizes.paddingVeryLarge}
          >
            <HeaderSubtitle
              type={medias.medium ? 'huge' : 'veryLarge'}
              title={trans('index.payments.title')}
              className="payment-header"
              bodyClassName="payment-header-subtext"
            >
              {trans('index.payments.text')}
            </HeaderSubtitle>
            <Grid
              className="payment-cards"
              columns={2}
              columnDefs={['auto']}
              gapColumn={sizes.paddingLarge}
            >
              <DateBox
                passed
                day="12th"
                month="Sept"
              />
              <HeaderSubtitle
                title={trans('index.payments.example1.title')}
                bodyClassName="payment-cards-subtext"
              >
                {trans('index.payments.example1.text')}
              </HeaderSubtitle>
              <DateBox
                day="12th"
                month="Oct"
                passed={false}
              />
              <HeaderSubtitle
                title={trans('index.payments.example2.title')}
              >
                {trans('index.payments.example2.text')}
              </HeaderSubtitle>
              <div/>
            <Image className="payment-accept" src={payment}/>
            </Grid>
          </Grid>
        </Container>
        <Container tag="section">
          <HeaderSubtitle
            type={medias.medium ? 'huge' : 'veryLarge'}
            title={trans('index.itinerary.title')}
            wrapperClassName="itinerary-header"
          >
            {trans('index.itinerary.text')}
          </HeaderSubtitle>
          <Image
            className="payments-example"
            src={medias.medium ? itinerary : itineraryMobile}
          />
        </Container>
      </Image>
      <Container tag="section" className="commission">
        <HeaderSubtitle
          type={medias.medium ? 'huge' : 'veryLarge'}
          title={trans('index.comm.title')}
        >
          {trans('index.comm.text1')}
        </HeaderSubtitle>
        <br/>
        <Body className="comm-body">
          {trans('index.comm.text2')}
        </Body>
      </Container>
      <Container
        className="join-us"
        tag={Grid}
        proxy={{
          columns: medias.medium ? 2 : 1,
          columnDefs: ['3fr', '2fr'],
          gapColumn: sizes.paddingHuge,
        }}
      >
        <Image
          alt=""
          src={partialLogo}
          className="join-us-logo"
        />
        <HeaderSubtitle
          type={medias.medium ? 'huge' : 'veryLarge'}
          wrapperClassName="join-us-header"
          className="join-us-title"
          title={trans('index.join.title')}
        >
          {trans('index.join.text')}
        </HeaderSubtitle>
        <div>
          <Input
            invert
            required
            success={subscribed2}
            value={email2}
            type="email"
            autoComplete="email"
            label={trans('index.join.input')}
            placeholder={trans('index.join.placeholder')}
            onChange={(name, val, e) => setEmail2(val)}
          />
          <Button
            block
            disabled={subscribed2}
            onClick={() => onSubmit2(email2)}
          >
            {trans('index.join.submit')}
          </Button>
        </div>
      </Container>
      <Container desktop>
        <Image
          className="tribe"
          width="100%"
          alt=""
          src={medias.medium ? tribe : tribeMobile}
        />
      </Container>
    </main>
  );
}
