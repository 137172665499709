import React from 'react';
import classnames from 'classnames';
import './Body.scss';

const classes = {
  regular: 'body-regular',
  small: 'body-small'
};

export default function Body (props) {
  return (
    <p className={classnames(props.className, classes[props.type] || classes.regular)}>
      { props.children }
    </p>
  );
};
