import React from 'react';
import classnames from 'classnames'
import useImage from 'Hooks/useImage';
import Body from 'Components/Text/Body';

import './Image.scss';

function containerStyles (props, loaded) {
  if (props.fill) {
    const styles = {};

    if (props.height) {
      styles.height = `${props.height}px`;
    }

    if (loaded && props.src) {
      styles.backgroundImage = `url(${props.src})`;
    }

    return styles;
  }

  if (props.aspectX && props.aspectY) {
    const styles = {
      paddingTop: `${(props.aspectY / props.aspectX) * 100}%`
    };

    if (loaded && props.src) {
      styles.backgroundImage = `url(${props.src})`;
    }

    return styles;
  }

  return {
    height: props.height,
    width: props.width,
  };
}

/**
 * The Image component.
 *
 * Can be used as:
 * A regular inline-block image.
 * A fixed-width fixed-height inline-block image with placeholder.
 * An aspect-ratio bound image with placeholder.
 * As aspect-fill image with placeholder.
 *
 * @prop string  src       The image src.
 * @prop string  alt       The image alt.
 * @prop bool?   fill      Whether the image should be aspect fill.
 * @prop number? aspectX   The X aspect of the aspect ratio.
 * @prop number? aspectY   The Y aspect of the aspect ratio.
 * @prop number? height    The height of the image, not used when aspect is specified.
 * @prop number? width     The width of the image, not used when aspect is specified.
 * @prop string? className Classes to add to the container.
 *
 * @see http://jacksonkr.com/content/uiimageview-mode-aspect-fit-vs-aspect-fill
 */
export default function Image (props) {
  const aspect = (props.aspectX && props.aspectY) || props.fill;
  const [loaded, failed] = useImage(props.src);
  const Tag = props.tag || 'div';

  return (
    <Tag
      role={aspect ? 'img' : null}
      aria-label={aspect ? props.alt : null}
      className={classnames(props.className, {
        'image-cont-loading': !loaded && props.src !== null,
        'image-cont-aspect': aspect,
        'image-cont-img': !aspect,
        'image-cont-failed': failed && props.src !== null,
      })}
      style={containerStyles(props, loaded)}
    >
      {!aspect && failed && props.src !== null
        ? <Body>
            This image failed to load:
            <br/>
          </Body>
        : null
      }
      {!aspect && props.src !== null
        ? <img
            src={props.src}
            alt={props.alt || ''}
            height={props.height || 'auto'}
            width={props.width || 'auto'}
          />
        : null
      }
      {props.children}
      {aspect && failed && props.src !== null
        ? <Body className="image-error">
            This image failed to load:
            <br/>
            <br/>
            {props.alt}
          </Body>
        : null
      }
    </Tag>
  );
}
