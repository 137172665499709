import React from 'react';
import classnames from 'classnames';
import Body from '../Text/Body';

export default function Error (props) {
  return typeof props.error === 'string'
    ? <Body type="small" className={classnames(props.className, 'input-error')} role="alert">
        {props.error}
      </Body>
    : null
}
