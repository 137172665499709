import React from 'react';
import Body from './Body';
import Header from './Header';
import classnames from 'classnames';

import './HeaderSubtitle.scss';

export default function HeaderSubtitle (props) {
  return (
    <div className={classnames(props.wrapperClassName, 'header-subtitle-wrapper')}>
      <Header
        tag={props.tag}
        className={classnames(
          props.className,
          'header-subtitle',
        )}
        type={props.type || 'regular'}
      >
        { props.title }
      </Header>
      <Body
        className={classnames(props.bodyClassName, 'header-subtext')}
      >
        { props.children }
      </Body>
    </div>
  );
}
