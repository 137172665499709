import React from 'react';
import Button from '../Form/Button/Button';
import Link from '../Text/Link';
import Container from '../Layout/Container';
import Image from 'Components/Presentation/Image';
import { trans } from '../../Helpers/Lang';
import logo from 'assets/logo.svg';
import './Header.scss';

export default function Header (props) {
  return (
    <header className="page-header">
      <Container tag="nav" className="header-items-container">
        <Image
          src={logo}
          alt=""
          className="header-logo"
        />
        {props.basic
          ? null
          :
            <>
              <Link className="nav-link">{trans('header.roster')}</Link>
              <Link className="nav-link">{trans('header.promoter')}</Link>
              <Link className="nav-link">{trans('header.login')}</Link>
              {props.authenticated
                ? <Image
                    className="header-user-image"
                    src={props.userImageSrc}
                    height="36px"
                    width="36px"
                  />
                : <Button
                    className="header-sign-up-btn"
                    type="primary"
                  >
                    {trans('header.signUp')}
                  </Button>
              }
            </>
        }
      </Container>
    </header>
  );
}
