import { useState, useEffect } from 'react';
import { loadImage } from '../Helpers/Dom';

/**
 * Pre-loads an image based on a given src.
 *
 * @param String src The image src.
 *
 * @return [Boolean loaded, Boolean failed] The loaded and failed states of the image.
 */
export default function (src) {
  const [loaded, setLoaded] = useState(false);
  const [failed, setFailed] = useState(false);

  useEffect(() => {
    setFailed(false);

    if (src === null) {
      setLoaded(false);

      return;
    }

    loadImage(src)
      .then(src => setLoaded(true))
      .catch(() => setFailed(true));
  }, [src]);

  return [loaded, failed];
}
