import React from 'react';
import moment from 'moment';
import Clock from 'Components/Image/Icon/Clock';
import Tick from 'Components/Image/Icon/Tick';
import classnames from 'classnames';
import Title from 'Components/Text/Title';
import Body from 'Components/Text/Body';
import useMoment from 'Hooks/useMoment';

import colours from 'style/colours.scss';
import sizes from 'style/sizes.scss';

import './DateBox.scss';

export default function DateBox (props) {
  const date = props.date instanceof moment ? props.date : moment(props.date);
  const now = useMoment();

  let passed = false;

  if (props.date) {
    passed = props.date < now;
  } else if (props.passed !== undefined) {
    passed = props.passed;
  }

  return (
    <div
      className={classnames('date-box', {
        'date-box-pre': !passed,
        'date-box-post': passed,
      })}
    >
      {passed
        ? <Tick size={sizes.iconSmall} fill={colours.positive} className="date-box-icon"/>
        : <Clock size={sizes.iconSmall} fill={colours.accentTertiary} className="date-box-icon"/>
      }
      <Title>
        {props.day || date.format('Do')}
      </Title>

      <Body type="small">
        {props.month || date.format('MMM')}
      </Body>
    </div>
  );
}
