import React from 'react';
import Container from '../Layout/Container';
import Image from 'Components/Presentation/Image';
import { trans } from '../../Helpers/Lang';
import groups from '../../Content/footer/links';
import Link from '../Text/Link';
import Title from '../Text/Title';
import logo from 'assets/logo.svg';

import './Footer.scss';

export default function Footer (props) {
  let sets = groups
    .map(group => ({
      ...group,
      links: group.links.filter(
        link => !link.filter || link.filter(props)
      )
    }))
    .filter(group => group.links.length);

  return (
    <footer className="footer">
      <Container className="footer-cont">
        <div className="footer-logo-cont">
          <Image alt="Tribe" src={logo}/>
          <small className="footer-legal">
            { trans('footer.legal') }
          </small>
        </div>
        <div className="footer-link-cont">
          {sets.map(group => (
            <div
              key={group.title}
              className="footer-link-group"
            >
              <Title className="footer-group-title">
                { group.title }
              </Title>

              {group.links.map(link => (
                <Link
                  key={link.text}
                  className="footer-group-link"
                >
                  { link.text }
                </Link>
              ))}
            </div>
          ))}
        </div>
      </Container>
    </footer>
  );
}
