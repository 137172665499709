import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: "flyrstaging.firebaseapp.com",
  databaseURL: "https://flyrstaging.firebaseio.com",
  projectId: "flyrstaging",
  storageBucket: "flyrstaging.appspot.com",
  messagingSenderId: "199318233781",
  appId: "1:199318233781:web:8a0c3a795ad58ace"
});

export default function useFirebase() {
    return firebase;
}
