import React from 'react';
import classnames from 'classnames';
import './Container.scss';

export default function Container (props) {
  const Tag = props.tag || 'div';

  return (
    <Tag
      {...props.proxy}
      className={classnames(
        props.desktop ? 'desktop-container' : 'container',
        props.className
      )}
    >
      { props.children }
    </Tag>
  );
}
