import React from 'react';
import Image from 'Components/Presentation/Image';
import Title from 'Components/Text/Title';
import Body from 'Components/Text/Body';

import './Feature.scss';

export default function Feature (props) {
  return (
    <div>
      <Image
        className="feature-img"
        src={props.src}
        alt={props.alt}
        width={props.imageWidth}
        height={props.imageHeight}
      />
      <Title
        className="feature-title"
        type="large"
      >
        {props.title}
      </Title>
      <Body>
        {props.text}
      </Body>
    </div>
  );
}
