import { useState, useEffect } from 'react';
import moment from 'moment';

/**
 * Subsribes a component to time updates.
 *
 * @param Number refresh How long in milliseconds between time state updates.
 *
 * @return moment
 */
export default function (refresh = 60000) {
  const [now, setNow] = useState(moment());

  useEffect(() => {
    const timeout = setTimeout(
      () => setNow(moment()),
      refresh
    );

    return () => clearTimeout(timeout);
  });

  return now;
}
