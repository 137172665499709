import React from 'react';
import classnames from 'classnames';
import { objectFilter } from '../../Helpers/Object';

const remove = ['tag', 'className', 'columns', 'columnDefs', 'gapColumn', 'gapRow', 'gap'];

function gapVal(specificVal, genericVal, def = 0) {
  return specificVal || genericVal || def;
}

function columnVal(specificVal, def = '1fr') {
  return specificVal || def;
}

function columnString(columns, columnDefs = []) {
  let string = '';

  for (var i = 0; i < columns; i++) {
    string += `${columnVal(columnDefs[i])} `;
  }

  return string;
}

/**
 * Arranges it's Children as a grid.
 *
 * @prop string className - The extra classname(s) for the grid.
 * @prop int columns - The number of columns in the griw.
 * @prop array|obj columnDefs - The definition of columns.
 * @prop string gap - The gap space between rows / columns.
 * @prop string gapRow - The gap space between rows, overrides gap.
 * @prop string gapColumn - The gap space between columns, overrides gap.
 * @prop mixed children - The children to put in a grid.
 */
export default function Grid(props) {
  const Tag = props.tag || 'div';

  const tagProps = objectFilter(
    props,
    (item, key) => !remove.includes(key)
  );

  return (
    <Tag
      {...tagProps}
      className={classnames('st-grid', props.className)}
      style={{
        gridTemplateColumns: columnString(props.columns, props.columnDefs),
        display: 'grid',
        gridGap: `${gapVal(props.gapRow, props.gap)} ${gapVal(props.gapColumn, props.gap)}`
      }}
    >
      {props.children}
    </Tag>
  );
}
