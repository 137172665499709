//import React from 'react';
//import Link from '../Containers/Link';
//import Alert from '../Components/Icon/Alert';
//import { approvableArtist, approvablePromoter, pendingArtist, pendingPromoter } from './User';

export function focusInCurrentTarget({ relatedTarget, currentTarget }) {
  if (relatedTarget === null || relatedTarget === undefined) return false;

  let node = relatedTarget.parentNode;

  while (node !== null) {
    if (node === currentTarget) return true;
    node = node.parentNode;
  }

  return false;
}

//export function generateLinks(links, active) {
  //return links.map((link, index) => (
    //React.isValidElement(link)
      //? link
      //: <Link
          //to={link.to}
          //className={active && active === link.active ? 'active' : ''}
          //key={link.key || link.active || index}
        //>
          //{link.label || ''} {link.alert ? <Alert/> : null}
        //</Link>
  //));
//}

//export function headerSpace(user, padding = 0) {
  //return padding
       //+ (approvableArtist(user) || pendingArtist(user) ? 50 : 0)
       //+ (approvablePromoter(user) || pendingPromoter(user) ? 50 : 0);
//}

export function loadImage(src) {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.addEventListener('load', e => {
      resolve(src);
    });

    img.addEventListener('error', e => {
      reject();
    });

    img.src = src;
  });
}

export function unDataUri(url) {
  return url.replace('data:*/*;base64,', '');
}
