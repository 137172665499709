import React, { Component } from 'react';
import { objectFilter } from '../../Helpers/Object';
import Grid from '../Layout/Grid';
import Button from '../Form/Button/Button';
import Error from './Error';
import Header from '../Text/Header';
import Tick from '../Image/Icon/Tick';

import classnames from 'classnames';
import Cleave from 'cleave.js/react';

import colors from 'style/colours.scss';

import 'cleave.js/dist/addons/cleave-phone.gb';
import './Input.scss';

const remove = [
  'options',
  'label',
  'sublabel',
  'initial',
  'error',
  'actionText',
  'actionDisabled',
  'onAction',
  'resolving',
  'invert',
  'success'
];

/**
 * The Input component.
 *
 * @prop string label - The label contents
 * @prop string name - The input name
 * @prop string placeholder - The input placeholder
 * @prop string initial - The input's initial value
 * @prop string type - The input type
 * @prop int step - The number input step
 * @prop int min - The number input min
 * @prop int max - The number input max
 * @prop function onChange - The input onChange handler
 * @prop object options - The Cleave input options
 * @prop bool|string error - Whether the input has an error, provide a string to show a message
 * @prop bool disabled - Whether the input is disabled
 * @prop bool invert - Whether to invert the styles of the input.
 *
 * @see https://nosir.github.io/cleave.js/
 */
export default class Input extends Component {
  constructor (props) {
    super(props);

    this.state = {
      value: props.initial || ''
    };
  }

  onChange = e => {
    this.setState({
      value: e.target.value
    });

    if (typeof this.props.onChange === "function") {
      this.props.onChange(
        this.props.name,
        e.target.value,
        e
      );
    }
  }

  render () {
    const state = this.state;
    const props = this.props;

    const inputProps = objectFilter(
      props,
      (item, key) => !remove.includes(key)
    );

    const Tag = props.options ? Cleave : 'input';

    // Keep inputProps at the top of Input to allow overriding.
    return (
      <label className={classnames('input-cont', { 'input-cont-invert': props.invert })}>
        {props.label
          ? <Header tag="div" className="input-label">
              {props.label}
            </Header>
          : null
        }
        <Grid columns={props.onAction ? 2 : 1} columnDefs={['1fr', 'auto']}>
          <div style={{ position: 'relative' }}>
            <Tag
              {...inputProps}
              className={classnames(
                'input',
                props.className,
                {
                  'input-haserror': props.error,
                  'input-hasaction': props.onAction
                }
              )}
              value={props.value || state.value}
              onChange={this.onChange}
            />
            {props.success && <Tick className="input-success" fill={colors.positive}/>}
          </div>
          {props.onAction
            ? <Button
                className="input-action"
                onClick={props.onAction}
                disabled={props.disabled || props.actionDisabled}
                resolving={props.resolving}
              >
                {props.actionText}
              </Button>
            : null
          }
        </Grid>
        <Error error={props.error}/>
      </label>
    );
  }
}
