import React from 'react';
import classnames from 'classnames';
import { objectKeyFilter } from 'Helpers/Object';
import './Button.scss';

const type = {
  primary: 'btn-primary',
  accentPrimary: 'btn-accent-primary',
  destructiveOne: 'btn-destructive-primary',
  destructiveTwo: 'btn-destructive-secondary'
}

const remove = ['block'];

export default function Button (props) {
  return (
    <button
      {...objectKeyFilter(props, key => !remove.includes(key))}
      className={classnames(
        props.className,
        'btn',
        type[props.type || 'primary'],
        props.block && 'btn-block',
      )}
    >
      { props.children }
    </button>
  );
}
