import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Header from './Components/Page/Header';
import Footer from './Components/Page/Footer';

import Index from './Pages/Index';

import './App.scss';

export default function App () {
  return (
    <Router>
      <div>
        <Header basic/>
        <Route exact path="/" component={Index}/>
        <Footer/>
      </div>
    </Router>
  );
}
